@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fixed-height {
  height: 580px;
}

.bg-primary {
  background-color: #154680 !important;
}

.bg-success {
  background-color: #017b92 !important;
}

.bg-teal {
  background-color: #004552 !important;
}

.bg-indigo {
  background-color: #172849 !important;
}

.bg-amethyst {
  background-color: #392f75 !important;
}

.bg-supporting-teal {
  background-color: #017b92 !important;
}

.bg-supporting-ash {
  background-color: #4e4b66 !important;
}

.bg-medium-warning {
  background-color: #e9d786 !important;
}

.bg-medium-ash {
  background-color: #a0a3bd !important;
}

.bg-light-primary {
  background-color: #d3e3fd !important;
}

.bg-light-ash {
  background-color: #d9dbe9 !important;
}

.bg-plum {
  background-color: #432043 !important;
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.text-success {
  color: #017b92 !important;
}

.text-amethyst {
  color: #392f75;
}

.text-indigo {
  color: #172849;
}

.text-ash {
  color: #14142b;
}

.text-supporting-cyan {
  color: #99ffff !important;
}

.nav-tabs {
  border: 0 !important;
}

.nav-link {
  color: white !important;
}

.nav-link.active {
  background-color: #f6f6f6 !important;
  border-color: #f6f6f6 !important;
  color: #392f75 !important;
}
